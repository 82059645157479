@import "./../../../assets/css/variables.scss";
.footer {
    padding-top: $paddingTop30px;

    ul {
        display: $d-flex;
        justify-content: $justify-content-end;
        padding-right: $paddingRight258px;
        margin-bottom: $marginBottom0px;
        padding-bottom: $paddingBottom30px;
        list-style: $d-none;
    }

    li {
        margin: 0 $maginLeft8 0 $maginRight8;

        a {
            // color: $Colorwhite;
            font-weight: $fontWeight700;
            font-size: $fontSize16;
            line-height: $fontSize20;
            font-family: $fontFamilyRubik;
        }
    }
}

@media only screen and (max-width: 1440px) {
    .footer {
        ul {
            padding-right: 218px;
        }
    }
}
@media only screen and (max-width: 767px) {
    .footer {
        padding-top: $padding30px;
        ul {
            display: $d-flex;
            justify-content: $justify-content-center;
            padding-right: $Zeropx;
            margin-bottom: $Zeropx;
            padding-bottom: $padding20px;
            list-style: $d-none;
            padding-left: $Zeropx;
        }
    }
}

/* Footer CSS End */