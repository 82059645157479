@import "../../assets/css/functions.scss";

// FontSize Variables
$fontSize10: rem(10);
$fontSize12: rem(12);
$fontSize13: rem(13);
$fontSize14: rem(14);
$fontSize15: rem(15);
$fontSize16: rem(16);
$fontSize18: rem(18);
$fontSize20: rem(20);
$fontSize22: rem(22);
$fontSize24: rem(24);
$fontSize26: rem(26);
$fontSize28: rem(28);
$fontSize32: rem(32);
$fontSize35: rem(35);
$fontSize36: rem(36);
$fontSize40: rem(40);
$fontSize64: rem(64);

// LineHeight Variables
$lineHeightNormal: normal;
$lineHeight50: rem(50);
$lineHeight14: rem(14);
$lineHeight16: rem(16);
$lineHeight17: rem(17);
$lineHeight18: rem(18);
$lineHeight20: rem(20);
$lineHeight21: rem(21);
$lineHeight24: rem(24);
$lineHeight26: rem(26);
$lineHeight28: rem(28);
$lineHeight32: rem(32);
$lineHeight38: rem(38);
$lineHeight40: rem(40);
$lineHeight92: rem(92);

// FontWeight Variables
$fontWeightNormal: normal;
$fontWeight300: 300;
$fontWeight400: 400;
$fontWeight500: 500;
$fontWeight600: 600;
$fontWeight700: 700;

// Colors  Variables
$BtnPrimaryColorBlue: #4D7CFE;
$PrimaryColorBlueHover: #005ca5;
$secondaryColor: #6dd230;
$Colorwhite: #fff;
$ColorBlack: #000;
$ColorDanger: #FF7171;
$ColorDark: #252631;
$ColorDarkGrey: #383838;
$ColorDarkGrey333: #333333;
$ColorDarkGreyHeading: #1B1E24;
$ColorLightGrey: #98a9bc;
$ColorBorderGrey: #E8ECEF;
$ColorBackgroundGrey: #F7F8FA;
$ColorBackgroundGreyLight: #F2F4F6;
$ColorBackgroundGreyLightAFB: #F8FAFB;
$ColorgreyGreyCheckbox: #778CA2;
$BorderColor: #dee2e6;
$BorderColorDropdown: #D7DADF;
$backgroundTransparent: transparent;
$backgroundgrey: #EFEFEF;
$backgroundBtngrey: #7A9FC3;
$shimmerBg: #f6f6f6;

// Display Variables
$d-flex: flex;
$d-block: block;
$d-inlineblock: inline-block;
$d-none: none;

// Flex Direction Variables

$flexDirectionColumn: column;

// Align Text Variables
$justify-content-center: center;
$justify-content-end: flex-end;
$textAlignCenter: center;
$textleft :left;
$justify-content-SpaceBetween: space-between;
$justify-content-FlexStart: flex-start;
$alignItemsCenter: center;
$alignItemsFlexStart: flex-start;
$verticalAlignMiddle: middle;
$flexWrap: wrap;

// Radius Variables

$borderRadius0: 0px;
$borderRadius2: 2px;
$borderRadius3: 3px;
$borderRadius4: 4px;
$borderRadius8: 8px;
$borderRadius10: 10px;
$borderRadius12: 12px;
$borderRadius20: 20px;
$borderRadius50 :50px;
$borderRadius100 :100px;
$borderRadius100Per :100%;

// Border Variables
$border0: 0px;

// LetterSpacing Variables
$letterSpacing2px: 2px;
$letterSpacing05px: 0.5px;

// Positions Variables
$positionRelative: relative;
$positionAbsolute: absolute;
$positionFixed: fixed;

// Z-index Variables
$z-index1: 1;
$z-index2: 2;


// Width Variables
$widthAuto: auto;
$width20: 20px;
$width22: 22px;
$width25: 25px;
$width30: 30px;
$width35: 35px;
$width36: 36px;
$width3022: 30.22px;
$width38px: 38px;
$width50px: 50px;
$width52px: 52px;
$width80: 80px;
$width87: 87px;
$width100: 100px;
$width120: 120px;
$width140: 140px;
$width150: 150px;
$width170: 170px;
$width225: 225px;
$width250: 250px;
$width50 : 50%;
$width100Per: 100%;
$maxWidth310px: 310px;
$maxWidth333px: 333px;
$maxWidth433px: 433px;
$maxWidth450px: 450px;
$maxWidth532px: 532px;

// Height Variables
$heightAuto: auto;
$height10px: 10px;
$height20px: 20px;
$height22px: 22px;
$height16px: 16px;
$height25px: 25px;
$height30px: 30px;
$height3022: 30.22px;
$height34: 34px;
$height35: 35px;
$height36: 36px;
$height38: 38px;
$height40: 40px;
$height50: 50px;
$height100px: 100px;
$height100vh: 100vh;
$height100per: 100%;
$minHeight46px: 46px;
$Height46px: 46px;
$Height52px: 52px;
$Height60px: 60px;
$Height64px: 64px;
$Height500px: 500px;


// Margin Variables
$marginZero: 0px;
$maginRight0: 0px;
$magin3px: 3px;
$magin4px: 4px;
$magin5px: 5px;
$maginRight8: 8px;
$maginRight12: 12px;
$maginLeft8: 8px;
$maginLeft12: 12px;
$marginBottom6rem: 0.6rem;
$marginBottom0px: 0px;
$marginTop48px: 48px;
$margin9px: 9px;
$margin10px: 10px;
$margin18px: 18px;
$margin16px: 16px;
$margin14px: 14px;
$margin15px: 15px;
$marginAuto: auto;
$margin20px: 20px;
$margin22px: 22px;
$margin24px: 24px;
$margin25px: 25px;
$margin27px: 27px;
$margin30px: 30px;
$margin35px: 35px;
$margin43px: 43px;
$margin45px: 45px;
$margin47px: 47px;
$margin50px: 50px;
$margin60px: 60px;
$margin70px: 70px;
$margin80px: 80px;
$margin90px: 90px;
$margin100px: 100px;
$margin120px :120px;


// Padding Variables
$paddingZero: 0px;
$paddingLeft55rem: 0.55rem;
$paddingRight55rem: 0.55rem;
$paddingRight113: 113px;
$paddingLeft113: 113px;
$paddingTop70px: 70px;
$paddingTop30px: 30px;
$paddingRight258px: 258px;
$paddingBottom48px: 48px;
$paddingBottom30px: 30px;
$padding5px: 5px;
$padding8px: 8px;
$padding12px: 12px;
$padding13px: 13px;
$padding14px: 14px;
$padding15px: 15px;
$padding10px: 10px;
$padding19px: 19px;
$padding20px: 20px;
$padding22px: 22px;
$padding24px: 24px;
$padding26px: 26px;
$padding30px: 30px;
$padding31px: 31px;
$padding33px: 33px;
$padding35px: 35px;
$padding40px: 40px;
$padding42px: 42px;
$padding45px: 45px;
$padding50px: 50px;
$padding60px: 60px;
$padding70px: 70px;
$padding72px: 72px;
$padding80px: 80px;
$padding90px: 90px;
$padding100px: 100px;
$padding100px: 100px;
$padding157px: 157px;
$padding215px: 215px;

// Background Size Variables
$backgroundSizeCover: cover;
$backgroundnorepeat: no-repeat;

// Fontfamily Variables
$fontFamilyRubik: 'Rubik', sans-serif;

// Float Variables
$floatRight: right;
$floatLeft: left;
$floatNone: none;

// Webkit  Appearance Variables
$-webkit-appearance: none;

// CamelCase Variables
$textUppercase: uppercase;
$capitalize: capitalize;
$lowercase: lowercase;

// Cursor Arrow Variables
$cursorPointer: pointer;

// OPacity Variables
$opacityZero: 0;
$opacityOne: 1;

// Common variables
$Zeropx: 0px;
$Onepx: 1px;
$Twopx: 2px;
$Threepx: 3px;
$Fivepx: 5px;
$Eightpx: 8px;
$Tenpx: 10px;
$Fifteenpx: 15px;
$Twentypx: 20px;
$Thirtypx: 30px;

// Text Wrap

$whiteSpaceNoWrap: nowrap;

// Overflow Properties
$OverflowHidden: hidden;