@import '../../assets/css/main.scss';
@import '../../assets/css/variables.scss';

body {
.signupWrapper {
    .signupContent {
        .signupPopup {
            .formOuter {
                input {
                    border-color: $ColorLightGrey;
                }
                .containcheck {
                    .checkmark {
                        border: 1px solid $ColorLightGrey;
                    }
                }
            }
        }
    }
}
}