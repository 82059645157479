@import '../../../../assets/css/variables.scss';
@import '../../../../assets/css/main.scss';
.AddteamPopup,.deletePopup  {
    .modal-dialog {
        margin-top: $margin120px;
        max-width: 575px!important;
    }

    .modal-content {
        background: $Colorwhite;
        box-shadow: 6px 6px 14px rgb(0 0 0 / 8%);
        border-radius: $borderRadius8;
        height: $heightAuto;
        .modal-header {
            display: $d-block;
            align-items: $alignItemsCenter;
            justify-content: $justify-content-center;
            padding: 100px $padding20px $padding10px;
            border-bottom: 0px solid #dee2e6;
            text-align: $textAlignCenter;

            .ModalLogo {
                background: $Colorwhite;
                box-shadow: 6px 6px 14px rgb(0 0 0 / 8%);
                border-radius: 50%;
                height: 130px;
                width: 130px;
                display: $d-flex;
                align-items: $alignItemsCenter;
                justify-content: $justify-content-center;
                position: $positionAbsolute;
                top: -65px;
                left: 50%;
                transform: translate(-50%, 0px);
            }

            .modal-title.h4 {
                font-weight: $fontWeight500;
                font-size: $fontSize20;
                line-height: $lineHeight16;
                text-align: $textAlignCenter;
                color: $ColorDark;
                font-family: $fontFamilyRubik;
            }

            .closebutton {
                position: $positionAbsolute;
                right: 12px;
                top: 12px;
                cursor: $cursorPointer;
            }
        }

        .modal-body {
            padding: $paddingZero $padding30px $paddingZero;
            p {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                text-align: center;
                color: #778CA2;
                max-width: 350px;
                margin: 0 auto;
            }
        }

        .modal-footer {
            display: $d-flex;
            flex-wrap: wrap;
            align-items: $textAlignCenter;
            justify-content: $justify-content-end;
            padding: $paddingZero $padding24px $padding35px;
            border-top: 0px solid $BorderColor;
            border-bottom-right-radius: calc(0.3rem - 1px);
            border-bottom-left-radius: calc(0.3rem - 1px);
        }

        button.btn.btn-secondary {
            background: $ColorBackgroundGreyLight;
            border-radius: $borderRadius4;
            font-weight: $fontWeight500;
            font-size: $fontSize14;
            line-height: $lineHeight17;
            text-align: $textAlignCenter;
            text-transform: $textUppercase;
            color: $ColorgreyGreyCheckbox;
            height: $Height46px;
            width: 150px;
            border: $border0;
            margin-right: $margin30px;
        }

        button.btn.btn-primary {
            background: $BtnPrimaryColorBlue;
            border-radius: $borderRadius4;
            font-weight: $fontWeight500;
            font-size: $fontSize14;
            line-height: $lineHeight17;
            text-align: $textAlignCenter;
            text-transform: $textUppercase;
            color: $Colorwhite;
            height: $Height46px;
            width: 150px;
            border: $border0;
            letter-spacing: 0.8px;

            // &:hover {
            //     background: $PrimaryColorBlueHover;
            // }
        }

    }
}

.deletePopup {
    .modal-content {
        .modal-body {
            p {
                margin: 10px auto 20px;
            }
        }
    }
}    