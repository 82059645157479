@import './../../../assets/css/variables.scss';
@import './../../../assets/css/main.scss';


.overlay {
 //   display: none;
    position: $positionAbsolute;
    left: 0;
    right: 0;
    height: calc(100vh - 128px);
    width: 100%;
    background: rgba(0,0,0,0.3);
    z-index: 1;
    top: 128px;
}
.header-content {
    background: $Colorwhite;
    height: 64px;
    display: $d-flex;
    justify-content: space-between;
    align-items: $textAlignCenter;
    border-bottom: 1px solid #E8ECEF;
    padding: $paddingZero $padding30px;

    .logo-wrap {
        width: 15%;
        position: $positionRelative;
        cursor: $cursorPointer;
    }

    .SearchWrap {
        position: $positionRelative;
        width: 50%;
        height: $heightAuto;

        form {
            display: $d-flex;
            align-items: $textAlignCenter;
            width: $width100Per;
            position: $positionRelative;
        }

        label.form-label {
            position: $positionAbsolute;
            left: $Zeropx;
            top: $Zeropx;
            background: $ColorLightGrey;
            border-radius: 100px 0px 0px 100px;
            height: $Height46px;
            width: 110px;
            text-align: $textAlignCenter;
            align-items: $textAlignCenter;
            display: $d-flex;
            justify-content: $textAlignCenter;
            font-weight: $fontWeight500;
            font-size: $fontSize14;
            line-height: $lineHeight17;
            color: $Colorwhite;
            cursor: $cursorPointer;
            font-family: $fontFamilyRubik;
        }

        input.form-control {
            background: #F2F4F6;
            border: 1px solid #A1B8CF;
            border-radius: $borderRadius100;
            width: $width100Per;
            height: $Height46px;
            font-weight: $fontWeight400;
            font-size: $fontSize14;
            line-height: $lineHeight17;
            color: $ColorgreyGreyCheckbox;
            // padding: $paddingZero $paddingZero $paddingZero 130px;
            font-family: $fontFamilyRubik;
        }

        .searcIcon {
            position: $positionAbsolute;
            top: $Zeropx;
            right: 20px;
            cursor: $cursorPointer;
            background-color: $backgroundTransparent;
            border: $border0;
            width: $widthAuto;
        }

        .searchBtn:active,
        .searchBtn:focus {
            background-color: $backgroundTransparent  !important;
            border: $border0  !important;
            outline: $d-none;
            box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 0%) !important;
        }
    }

    .userImg.notificationDrop {
        button#dropdown-basic {
            img {
                width: $width30;
            }

            span.notificationCount {
                position: $positionAbsolute;
                right: -4px;
                font-size: $fontSize12;
                background: #FE4D97;
                width: $width20;
                height: $height20px;
                text-align: $alignItemsCenter;
                border-radius: $borderRadius50;
                color: $Colorwhite;
                padding: 2px $paddingZero;
            }
        }
        .markWrap {
            padding: $padding5px;
            position: $positionRelative;
            button.btn.btn-primary.btnMark {
                font-weight: $fontWeight400;
                font-size: $fontSize14;
                line-height: $lineHeight20;
                color: $ColorDark;
                background: $backgroundTransparent;
                border: $d-none;
            }
        }

        a.dropdown-item {
            p {
                margin: $margin10px $marginZero;
                text-transform: $capitalize;
            }
        }

        .dropdown-menu {
            padding: $padding12px;
        }
    }

    .user-wrap {
        display: $d-flex;
        align-items: $alignItemsCenter;

        .userImg.marginLeft {
            margin-left: $magin5px  !important;
        }

        .CreateBtn {
            background: $BtnPrimaryColorBlue;
            border-radius: $borderRadius4;
            height: 36px;
            width: $width100Per;
            font-family: $fontFamilyRubik;
            font-weight: $fontWeight400;
            font-size: $fontSize12;
            line-height: $lineHeight17;
            text-align: $textAlignCenter;
            text-transform: $textUppercase;
            color: $Colorwhite;
            padding: 11px 18px;

            img {
                margin-left: 8px;
            }
        }

        .CreateBtn:hover {
            text-decoration: $d-none;
        }

        span {
            cursor: $cursorPointer;
        }

        .userImg {
            margin-left: $margin20px;
            cursor: $cursorPointer;
            position: $positionRelative;
            padding-left: $padding15px;
        }
        .userImg.notificationDrop  {
            .dropdown-menu {
                padding: $paddingZero;
                .notificationScroll {
                    max-height: 350px;
                    overflow-y: auto;
                    padding: $paddingZero;
                .notiWrap {
                    padding: 18px;
                    span.notiImage {
                        width: 42px;
                        height: 42px;
                        background: #eee;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100px;
                        img {
                            width: 20px;
                        }
                    }      border-top: 1px solid #EDEDEE;
                    align-items: $alignItemsCenter;

                    &:hover {
                        background-color: #eee;
                    }
                    .notiContent {
                        h4 {
                            margin-bottom: $marginZero;
                            font-size: $fontSize14;
                        }    
                    }
                    .imgWrap {
                        margin-right: $margin15px;
                    }
                p {
                    // padding: $padding10px $padding20px;
                    margin-bottom: $marginZero;
                    font-size: $fontSize14;
                    color: $ColorgreyGreyCheckbox;
                    font-weight: $fontWeight400;
                }
                .notiDate {
                    font-size: $fontSize12;
                    color: $ColorgreyGreyCheckbox;
                }
                }
                &::before {
                    content: '';
                    position: $positionAbsolute;
                    width: $width20;
                    height: $height20px;
                    background: $Colorwhite;
                    right: $Zeropx;
                    transform: rotate(45deg);
                    top: -9px;
                    right: 17px;
                    border-left: 1px solid rgba(182, 182, 182);
                    border-top: 1px solid rgba(182, 182, 182);
                    z-index: -1;
                }
            }
            button.btnNoti-all {
                background: #F8FAFB;
                border-radius: 0px 0px 4px 4px;
                width: $width100Per;
                border: 1px solid #F8FAFB;
                padding: $padding12px;
                font-weight: $fontWeight500;
                font-size: $fontSize14;
                line-height: $lineHeight17;
                text-align: $textAlignCenter;
                text-transform: $textUppercase;
                color: $BtnPrimaryColorBlue;
                img {
                    margin-left: 5px;
                }
            }

            }
            span.notiImage {
                width: 42px;
                height: 42px;
                background: #eee;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                img {
                    width: 20px;
                }
            }
        }        
        .userImg:before {
            content: '';
            border-left: 1px solid #E8ECEF;
            position: $positionAbsolute;
            left: $Zeropx;
            top: $Twentypx;
            width: 1px;
            height: 40%;
        }

        #dropdown-basic {
            display: $d-block;
            background: $backgroundTransparent;
            border: $border0;
            white-space: normal;
            word-wrap: break-word;
            padding: $paddingZero;
            text-align: left;
            height: 64px;

            &:after {
                display: $d-none;
            }
        }

        #dropdown-basic:active,
        #dropdown-basic:focus {
            background-color: $backgroundTransparent  !important;
            border: $border0  !important;
            outline: $d-none;
            box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 0%) !important;
        }

        .dropdown-menu {
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.13);
            border: 1px solid rgba(182, 182, 182, 0.3);
            font-weight: 300;
            border-radius: 3px;
            filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.12));
            width: 172px;
            height: $heightAuto;
            padding: $paddingZero;
            transform: translate(0px, 49px) !important;
        }

        .dropdown-menu a {
            white-space: normal;
            border-bottom: 1px solid #E8ECEF;
            height: $height38;
            display: $d-flex;
            align-items: $alignItemsCenter;
            padding: $paddingZero $padding20px;
            font-family: $fontFamilyRubik;
            font-weight: $fontWeight400;
            font-size: $fontSize12;
            line-height: $lineHeight16;
            text-transform: $textUppercase;
            color: $ColorDark;

            span.InnerIcon {
                margin-right: 8px;
            }
        }

        .dropdown-item.active,
        .dropdown-item:active {
            color: $Colorwhite !important;
            text-decoration: $d-none;
            background-color: #007bff;
        }
    }
    .userImg.notificationDrop {
        .dropdown-menu {
            max-width: 350px;
            width: 350px;
            inset: 12px -10px auto auto !important;
            z-index: 3;
        }    
    }

    .userImg {
        #dropdown-basic {
            display: $d-block;
            background: $backgroundTransparent;
            border: $border0;
            white-space: normal;
            word-wrap: break-word;
            padding: $paddingZero;
            text-align: left;
            height: 64px;

        }

        #dropdown-basic:active,
        #dropdown-basic:focus {
            background-color: $backgroundTransparent  !important;
            border: $border0  !important;
            outline: $d-none;
            box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 0%) !important;
        }

        .dropdown-menu {
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.13);
            border: 1px solid rgba(182, 182, 182, 0.3);
            font-weight: $fontWeight300;
            border-radius: 3px;
            filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.12));
            width: 280px;
            height: $heightAuto;
            padding: $paddingZero;
            transform: translate(0px, 49px) !important;

            .UserHead {
                background: #F8FAFB;
                padding: 22px 14px;
                display: $d-flex;
                align-items: $alignItemsCenter;

                img {
                    margin-right: 14px;
                }

                h3 {
                    font-family: $fontFamilyRubik;
                    font-weight: $fontWeight500;
                    font-size: $fontSize14;
                    line-height: $lineHeight17;
                    color: #1B1E24;
                    margin-bottom: 3px;
                }

                p {
                    font-family: $fontFamilyRubik;
                    font-weight: $fontWeight400;
                    font-size: $fontSize14;
                    line-height: $lineHeight17;
                    color: #98A9BC;
                    margin-bottom: $marginBottom0px;
                }
            }
        }

        .dropdown-menu a {
            white-space: normal;
            border-bottom: 1px solid #E8ECEF;
            height: 38px;
            display: $d-flex;
            align-items: $alignItemsCenter;
            padding: $paddingZero $padding20px;
            font-family: $fontFamilyRubik;
            font-weight: $fontWeight400;
            font-size: $fontSize12;
            line-height: $lineHeight14;
            text-transform: $textUppercase;
            color: #252631;

            span.InnerIcon {
                margin-right: 8px;
            }
        }

        .dropdown-item.active,
        .dropdown-item:active {
            color: $Colorwhite !important;
            text-decoration: $d-none;
            background-color: $BtnPrimaryColorBlue;
        }

        .downarrow {
            margin-left: 8px;
        }
    }
}

@media (min-width:769px) {
    .desktophide {
        display: $d-none;
    }
}

@media only screen and (max-width: 1100px) {
    .header-content {
        .user-wrap {
            .userImg {
                margin-left: $margin10px;
                padding-left: $padding10px;
            }
        }
    }
}


@media (min-width:769px) and (max-width:992px) {
    .header-content {
        padding: $paddingZero $padding10px;

        .logo-wrap {
            width: 20%;
            position: $positionRelative;
        }

        .SearchWrap {
            position: $positionRelative;
            width: 30%;
            height: $heightAuto;

            label.form-label {
                position: $positionAbsolute;
                left: 0;
                top: 0px;
                background: #98A9BC;
                border-radius: 100px 0px 0px 100px;
                height: $Height46px;
                width: $widthAuto;
                padding: $paddingZero $padding30px;
                text-align: $textAlignCenter;
                align-items: $alignItemsCenter;
                display: $d-flex;
                justify-content: center;
                font-weight: $fontWeight500;
                font-size: $fontSize14;
                line-height: $lineHeight17;
                color: $Colorwhite;
                cursor: pointer;
                font-family: $fontFamilyRubik;
            }

            input.form-control {
                background: $ColorBackgroundGreyLight;
                border: 1px solid #A1B8CF;
                border-radius: $borderRadius100;
                width: $width100Per;
                height: $Height46px;
                font-weight: $fontWeight400;
                font-size: $fontSize14;
                line-height: $lineHeight17;
                color: $ColorgreyGreyCheckbox;
                padding: $paddingZero $paddingZero $paddingZero $padding20px;
                font-family: $fontFamilyRubik;
            }
        }

        .user-wrap .userImg {
            margin-left: 6px;
            cursor: pointer;
            position: $positionRelative;
            padding-left: $padding15px;
        }
    }
}



@media (max-width:768px) {
    .mobilehide {
        display: $d-none;
    }

    .SidebarWrapper {
        top: 64px;
        left: -50%;
        width: 80%;

        #menu-toggle-2 {
            display: $d-none;
        }

        .sidebar-nav {
            position: $positionAbsolute;
            top: 0px;
            width: $width100Per;
            margin: $marginZero;
            padding: $paddingZero;
            list-style: none;
            margin-top: 2px;
            transition: all 0.8s ease;
        }
    }

    .SidebarWrapper.toggle {
        width: 280px;
        transition: all 0.8s ease;
        position: $positionAbsolute;
        box-shadow: 6px 5px 5px 0px rgb(0 0 0 / 10%);
        top: 64px !important;
        left: 242px;

        #menu-toggle-2 {
            display: $d-none;
        }

        .sidebar-nav {
            position: $positionAbsolute;
            top: 0px;
            width: $width100Per;
            margin: $marginZero;
            padding: $paddingZero;
            list-style: none;
            margin-top: 2px;
            transition: all 0.8s ease;

            li {
                justify-content: flex-start;

                a {
                    display: $d-flex;

                    .icon-inner {
                        margin-right: 20px;
                    }
                }
            }

            .toggleHide {
                display: $d-block;
                transition: all 0.8s ease;
            }
        }

    }

    .ContentWrap.contentToggle {
        margin-left: $marginZero;
    }

    .header-content {
        background: $Colorwhite;
        height: $Height64px;
        display: $d-flex;
        justify-content: space-between;
        align-items: $textAlignCenter;
        border-bottom: 1px solid #E8ECEF;
        padding: $paddingZero $padding15px;

        .navbar-toggle {
            background-color: $backgroundTransparent  !important;
            border: $border0;
            padding: $paddingZero;
        }

        .logo-wrap {
            width: $widthAuto;
            position: $positionRelative;
        }

        .user-wrap .userImg {
            margin-left: 15px;
            cursor: pointer;
            position: $positionRelative;
            padding-left: $padding15px;
        }
    }

    #basic-menu2 .MuiMenu-paper {
        left: 150px !important;
        z-index: -1;
    }

    #basic-menu2 .MuiMenu-paper .css-6hp17o-MuiList-root-MuiMenu-list {
        filter: drop-shadow(6px 6px 14px rgba(0, 0, 0, 0.08));
        width: 190px;
        height: 193px;
    }

}

@media (min-width:710px) and (max-width:715px) {
    body {
        .LoginWrapper {
            .signupContent {
                height: calc(100vh - 170px);
            }    
        }
    }
}
@media only screen and (max-width: 575px) {
    .header-content {
        .userImg.notificationDrop {
            .dropdown-menu {
                max-width: 300px;
                width: 300px;
                inset: 10px -140px auto auto !important;
                .notificationScroll {
                    &::before {
                        right: 50%;
                    }
                    .notiWrap {
                        padding: 8px;
                    }
                }
            }
        }
        .userImg.marginLeft {
            .dropdown-menu.dropdown-menu-end {
                width: 200px;
            }
        }
    }    
}

@media only screen and (max-width: 480px) {
    
    header {
        .header-content {
            .logo-wrap {
                margin-left: 5px;
                img {
                    width: 85px;
                }
            }
            .user-wrap {
            .userImg {
                padding-left: $Fivepx;
                margin-left: $Fivepx;
            }
            }
            .userImg {
                #dropdown-basic {
                    img.rounded-circle {
                        width: 25px;
                        height: 25px;
                    }
                    .downarrow {
                        margin-left: 5px;
                    }
                }        
            }
        }
    }
}

@media only screen and (max-width: 350px) {
    .header-content {
        .user-wrap {
            #dropdown-basic {
                .CreateBtn {
                    padding: $padding10px;
                    img {
                        margin-left: 3px;
                    }
                }
            }
        }        
    }
}