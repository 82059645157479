@import 'node_modules/sass-math/math';
@import "./functions.scss";
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import "./variables.scss";

body {
    font-family: $fontFamilyRubik;
}

html {
    font-size: 62.5%;
}

body {

    // .reactEasyCrop_Cover_Horizontal {
    //     width: 300px !important;
    //     height: 300px !important;
    // }
    a,
    .btn,
    .btn-primary {
        transition: 0.5s all ease-in-out;
    }

    ::-webkit-input-placeholder,
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        /* Edge */
        color: $ColorLightGrey;
        opacity: $opacityOne;
    }

    :-ms-input-placeholder,
    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $ColorLightGrey;
        opacity: $opacityOne;
    }

    ::placeholder,
    input::placeholder,
    textarea::placeholder {
        color: $ColorLightGrey;
        opacity: $opacityOne;
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f9f9f9;
    }

    ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: $borderRadius20;
    }

    .ContentWrapper {
        padding: $padding30px;
        background: $ColorBackgroundGrey;
        position: $positionRelative;
    }

    .PageOuter {
        position: $positionRelative;
        background-color: $ColorBackgroundGrey;
        margin: $marginZero;
        height: $heightAuto;
        padding: $paddingZero $padding30px $padding30px $padding30px;
    }

    .ContentWrap {
        width: $widthAuto;
        display: $d-block;
        position: $positionRelative;
        margin-left: 242px;
        transition: all 0.5s ease-in-out;
        background: $ColorBackgroundGrey;
        height: $height100per;
    }

    .mb-30px {
        margin-bottom: $margin30px;
    }

    label.form-label {
        font-family: $fontFamilyRubik;
        font-style: $fontWeightNormal;
        font-weight: $fontWeight400;
        font-size: $fontSize14;
        line-height: $lineHeight17;
        color: $ColorDark;
    }

    :focus-visible {
        outline: -webkit-focus-ring-color auto 0px;
    }

    select.form-control,
    input.form-control {
        min-height: $minHeight46px;
        border-radius: $borderRadius4;
        border: 1px solid $ColorBorderGrey;
        color: $ColorBlack;
        font-family: $fontFamilyRubik;
        font-weight: $fontWeight400;
        font-size: $fontSize14;
        line-height: $lineHeight17;
        -webkit-appearance: $-webkit-appearance;
        background-image: $d-none  !important;
        padding: $padding15px;
        &:focus {
            box-shadow: $d-none  !important;
            border-color: $BtnPrimaryColorBlue;
            //color: $ColorBlack;
        }
    }

    
    .rbt-input-multi.form-control.rbt-input {
        min-height: $minHeight46px;
        border-radius: $borderRadius4;
        border: 1px solid $ColorBorderGrey;
        font-family: $fontFamilyRubik;
        font-weight: $fontWeight400;
        font-size: $fontSize14;
        line-height: $lineHeight17;
        padding: $padding15px;
    }
    textarea.form-control {
        border-color: $ColorBorderGrey;

        &:focus {
            box-shadow: $d-none  !important;
            border-color: $BtnPrimaryColorBlue;
        }
    }

    .dateWrap span.dateIcon {
        position: $positionAbsolute;
        top: 14px;
        right: $Thirtypx;
        background-image: url(../../assets/images/calenerGreyIcon.svg);
        background-size: 22px 22px;
        right: 15px;
        top: 12px;
        width: $width22;
        height: $height22px;
    }

    input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  &.form-control {
    appearance: none; // Fix appearance for date inputs in Safari
    min-width: 290px;
    width: $width100Per;
  }
}


    input[type="date"]::-webkit-calendar-picker-indicator {
        display: $d-none;
        
    }
        .AddteamPopup.teamWrapPopup {
            .modal-content {
                .modal-body {
                    .DropdownWrap.dropdown {
                        .dropdown-menu {
                            height: 160px;
                        }
                    }
                }
            }
        }
    .DropdownWrap {
        button {
            width: $width100Per;
            text-align: $textleft;
            background: $Colorwhite;
            border: 1px solid $ColorBorderGrey;
            border-radius: $borderRadius4;
            height: $Height46px;
            font-weight: $fontWeight400;
            font-size: $fontSize14;
            line-height: $lineHeight17;
            color: $ColorBlack;
            transition: 0.5s all ease-in-out;
            position: $positionRelative;

            &:focus {
                box-shadow: none !important;
                border-color: $BtnPrimaryColorBlue;
            }

            &:after {
                background-image: url(../../assets/images/angleDownIcon.svg);
                border: $d-none;
                position: absolute;
                right: 14px;
                top: $Twentypx;
                width: 15px;
                height: 8px;
                background-size: 15px 8px;
                background-repeat: no-repeat;
                transition: 0.5s all ease-in-out;
            }
        }

        .dropdown-menu {
            width: $width100Per;
            margin: $marginZero;
            padding: $paddingZero;
            height: $heightAuto;
            overflow: auto;
            white-space: break-spaces;

            a.dropdown-item {
                font-family: $fontFamilyRubik;
                padding: 11.5px 15px;
                font-weight: $fontWeight400;
                font-size: $fontSize14;
                line-height: $lineHeight17;
                color: $ColorDark;
                white-space: normal;
                text-transform: $capitalize;

                &:hover,
                &:active,
                &.active {
                    background: $ColorBackgroundGreyLight;
                }
            }
        }
    }


    /* Wall Product Css Start */

    .productOptions {
        display: $d-flex;
        position: $positionAbsolute;
        right: $Tenpx;
        bottom: -10px;
        opacity: $opacityZero;
        transition: 0.5s all ease-in-out;

        .dotsOptions {
            button {
                width: 30px;
                height: 30px;
                background-image: url(../images/am-share-img.png);
                background-repeat: no-repeat;
                background-position: 5px -56px;
                background-color: $Colorwhite;
                border-radius: $borderRadius100;
                border: $d-none;
                transition: 0.5s all ease-in-out;
                margin-left: 10px;

                &:after {
                    display: $d-none;
                }

                &:hover {
                    background-position: -27px -56px;
                    background-color: $BtnPrimaryColorBlue;
                }
            }

            .dropdown-menu {
                width: 190px;
                padding: $paddingZero;

                a.dropdown-item {
                    font-weight: $fontWeight400;
                    font-size: $fontSize12;
                    line-height: $lineHeight14;
                    color: $ColorDark;
                    padding: 7px $padding20px;
                    display: $d-flex;
                    align-items: $alignItemsCenter;
                    border: 1px solid $BorderColorDropdown;

                    span {
                        background-image: url(../images/am-share-img.png);
                        background-repeat: no-repeat;
                        width: 20px;
                        height: 20px;
                        display: $d-inlineblock;
                        margin-right: $margin10px;
                        transition: 0.5s all ease-in-out;
                    }

                    .editIcon {
                        background-position: 0 -89px;
                    }

                    .DeleteIcon {
                        background-position: 0 -124px;
                    }

                    .DuplicateIcon {
                        background-position: 0 -155px;
                    }

                    &:hover {
                        background: $BtnPrimaryColorBlue;
                        color: $Colorwhite
                    }

                    &:hover .editIcon {
                        background-position: -30px -89px;
                    }

                    &:hover .DeleteIcon {
                        background-position: -30px -124px;
                    }

                    &:hover .DuplicateIcon {
                        background-position: -30px -155px;
                    }

                }
            }

        }

        .shareOptions.show.dropdown {
            .dropdown-toggle {
                background-position: -27px 7px;
            }
        }

        .dotsOptions.show.dropdown {
            .dropdown-toggle {
                background-position: -27px -56px;
            }
        }

        .wishlistOptions.show.dropdown {
            .dropdown-toggle {
                background-position: -25px -27px;
            }
        }



        button.shareBtn {
            width: 30px;
            height: 30px;
            background-image: url(../images/am-share-img.png);
            background-repeat: no-repeat;
            background-position: 4px 7px;
            background-color: $Colorwhite;
            border-radius: $borderRadius100;
            border: $d-none;
            transition: 0.5s all ease-in-out;
            margin-left: $margin10px;
            font-size: $Zeropx;

            &:after {
                display: $d-none;
            }

            &:hover {
                background-position: -27px 7px;
                background-color: $BtnPrimaryColorBlue;
            }
        }


        .wishlistOptions {
            button {
                width: 30px;
                height: 30px;
                background-image: url(../images/am-share-img.png);
                background-repeat: no-repeat;
                background-position: 5px -27px;
                background-color: $Colorwhite;
                border-radius: $borderRadius100;
                border: $d-none;
                transition: 0.5s all ease-in-out;
                margin-left: 10px;

                &:after {
                    display: $d-none;
                }

                &:hover {
                    background-position: -25px -27px;
                    background-color: $BtnPrimaryColorBlue;
                }
            }
        }

        .shareOptions.show.dropdown {
            .dropdown-menu.show {
                background: $Colorwhite;
                box-shadow: 6px 6px 14px rgb(0 0 0 / 8%);
                border-radius: $borderRadius8;
                width: 350px;
                padding: $padding30px $paddingZero;
                text-align: $textAlignCenter;

                h2 {
                    font-weight: $fontWeight500;
                    font-size: $fontSize18;
                    line-height: $lineHeight20;
                    text-align: $textAlignCenter;
                    color: $ColorDark;
                }

                .justify-center {
                    flex-direction: $flexDirectionColumn;

                    span {
                        background: $backgroundgrey;
                        width: 48px;
                        height: 48px;
                        text-align: $textAlignCenter;
                        padding: $padding15px $paddingZero;
                        border-radius: $padding100px;
                        margin: $margin15px $marginAuto $Fivepx;
                    }

                    p {
                        font-weight: $fontWeight400;
                        font-size: $fontSize12;
                        line-height: $lineHeight14;
                        text-align: $textAlignCenter;
                        color: $ColorDark;
                    }

                    h5 {
                        font-weight: $fontWeight400;
                        font-size: $fontSize12;
                        line-height: $lineHeight14;
                        text-align: $textAlignCenter;
                        text-transform: $textUppercase;
                        color: $ColorgreyGreyCheckbox;
                    }
                }
            }
        }
    }


    // Alternate CSS Start
    .productOptions {
        display: $d-flex;
        position: $positionAbsolute;
        right: $Tenpx;
        bottom: -10px;
        opacity: $opacityZero;
        transition: 0.5s all ease-in-out;
        z-index: 9991;

        .dotsOptions {
            button {
                width: 30px;
                height: 30px;
                background-image: url(../images/am-share-img.png);
                background-repeat: no-repeat;
                background-position: 5px -56px;
                background-color: $Colorwhite;
                border-radius: $borderRadius100;
                border: $d-none; // Alternate CSS Start

                &:after {
                    display: $d-none;
                }

                &:hover {
                    background-position: -27px -56px;
                    background-color: $BtnPrimaryColorBlue;
                }
            }

            .dropdown-menu {
                width: 190px;
                padding: $paddingZero;

                a.dropdown-item {
                    font-weight: $fontWeight400;
                    font-size: $fontSize12;
                    line-height: $lineHeight14;
                    color: $ColorDark;
                    padding: 7px $padding20px;
                    display: $d-flex;
                    align-items: $alignItemsCenter;
                    border: 1px solid $BorderColorDropdown;

                    span {
                        background-image: url(../images/am-share-img.png);
                        background-repeat: no-repeat;
                        width: 20px;
                        height: 20px;
                        display: $d-inlineblock;
                        margin-right: $margin10px;
                        transition: 0.5s all ease-in-out;
                    }

                    .editIcon {
                        background-position: 0 -89px;
                    }

                    .DeleteIcon {
                        background-position: 0 -124px;
                    }

                    .DuplicateIcon {
                        background-position: 0 -155px;
                    }

                    &:hover {
                        background: $BtnPrimaryColorBlue;
                        color: $Colorwhite
                    }

                    &:hover .editIcon {
                        background-position: -30px -89px;
                    }

                    &:hover .DeleteIcon {
                        background-position: -30px -124px;
                    }

                    &:hover .DuplicateIcon {
                        background-position: -30px -155px;
                    }

                }
            }

        }

        .shareOptions.show.dropdown {
            .dropdown-toggle {
                background-position: -27px 7px;
            }
        }

        .dotsOptions.show.dropdown {
            .dropdown-toggle {
                background-position: -27px -56px;
            }
        }

        .wishlistOptions.show.dropdown {
            .dropdown-toggle {
                background-position: -25px -27px;
            }
        }

        .shareOptions {
            .shareBlock {
                h2 {
                    font-weight: $fontWeight500  !important;
                    font-size: $fontSize18  !important;
                    line-height: $lineHeight20  !important;
                    text-align: $textAlignCenter  !important;
                    color: $ColorDark  !important;
                }

                button {
                    width: 30px;
                    height: 30px;
                    background-image: url(../images/am-share-img.png);
                    background-repeat: no-repeat;
                    background-position: 4px 7px;
                    background-color: $Colorwhite;
                    border-radius: $borderRadius100;
                    border: $d-none;
                    transition: 0.5s all ease-in-out;
                    margin-left: $margin10px;

                    &:after {
                        display: $d-none;
                    }

                    &:hover {
                        background-position: -27px 7px;
                        background-color: $BtnPrimaryColorBlue;
                    }
                }
            }
        }

        .wishlistOptions {
            button {
                width: 30px;
                height: 30px;
                background-image: url(../images/am-share-img.png);
                background-repeat: no-repeat;
                background-position: 5px -27px;
                background-color: $Colorwhite;
                border-radius: $borderRadius100;
                border: $d-none;
                transition: 0.5s all ease-in-out;
                margin-left: 10px;

                &:after {
                    display: $d-none;
                }

                &:hover {
                    background-position: -25px -27px;
                    background-color: $BtnPrimaryColorBlue;
                }
            }
        }

        .shareOptions {
            .shareBlock {
                background: $Colorwhite;
                box-shadow: 0px 0px 14px rgb(0 0 0 / 8%);
                border-radius: $borderRadius8;
                width: 350px;
                padding: $padding30px $paddingZero;
                text-align: $textAlignCenter;
                position: $positionAbsolute;
                bottom: 40px;
                left: -220px;

                h2 {
                    font-weight: $fontWeight500;
                    font-size: $fontSize18;
                    line-height: $lineHeight20;
                    text-align: $textAlignCenter;
                    color: $ColorDark;
                }

                .justify-center {
                    flex-direction: $flexDirectionColumn;

                    span {
                        background: $backgroundgrey;
                        width: 48px;
                        height: 48px;
                        text-align: $textAlignCenter;
                        padding: $padding15px $paddingZero;
                        border-radius: $padding100px;
                        margin: $margin15px $marginAuto $Fivepx;
                    }

                    p {
                        font-weight: $fontWeight400;
                        font-size: $fontSize12;
                        line-height: $lineHeight14;
                        text-align: $textAlignCenter;
                        color: $ColorDark;
                    }

                    h5 {
                        font-weight: $fontWeight400;
                        font-size: $fontSize12;
                        line-height: $lineHeight14;
                        text-align: $textAlignCenter;
                        text-transform: $textUppercase;
                        color: $ColorgreyGreyCheckbox;
                    }
                }

                button.searchCloseBtn.btn.btn-primary {
                    background: none;
                    border: none;
                    box-shadow: none;
                    position: absolute;
                    right: 5px;
                    top: 5px;

                    img {
                        border-radius: 0 !important;
                        border: none !important;
                    }
                }
            }
        }
    }




    
    // Alternate CSS End

    .DropdownWrap.show.dropdown {
        button {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    /* Wall Product Css End */

    button.deactiveBtn.btn.btn-primary {
        background-color: $ColorgreyGreyCheckbox  !important;
        width: $width150;
        height: $Height46px;
        font-size: $fontSize14;
        margin-bottom: $marginZero;
        border-color: $ColorgreyGreyCheckbox;
    }

    button.activateBtn.btn.btn-primary {
        background-color: $BtnPrimaryColorBlue  !important;
        width: $width150  !important;
        height: $Height46px;
        font-size: $fontSize14;
        border-color: $BtnPrimaryColorBlue;
        margin-bottom: $marginZero;
    }

    form button.btn.btn-primary {
        background: $BtnPrimaryColorBlue;
        border-radius: $borderRadius4;
        width: $width100Per;
        height: $Height46px;
        font-weight: $fontWeight500;
        font-size: $fontSize14;
        line-height: $lineHeight17;
        text-align: $textAlignCenter;
        text-transform: $textUppercase;
        color: $Colorwhite;
        border-color: $BtnPrimaryColorBlue;
        font-family: $fontFamilyRubik;
        margin-bottom: $margin18px;
    }

   

    button.disableBtn.btn.btn-primary {
        border-radius: $borderRadius4;
        width: $width100Per;
        height: $Height46px;
        font-weight: $fontWeight500;
        font-size: $fontSize14;
        line-height: $lineHeight17;
        text-align: $textAlignCenter;
        text-transform: $textUppercase;
        color: $Colorwhite;
        font-family: $fontFamilyRubik;
        margin-bottom: $margin18px;
        background-color: $ColorgreyGreyCheckbox  !important;
        border-color: $ColorgreyGreyCheckbox;
    }

    button.showPassword.btn.btn-primary,
    button.hidePassword.btn.btn-primary {
        background: $backgroundTransparent;
        width: $widthAuto;
        border: $d-none;
        position: $positionAbsolute;
        right: 15px;
        margin: $marginZero;
        padding: $paddingZero;
        height: $heightAuto;
        bottom: 14px;
    }

    button.recoverPassword.btn.btn-primary {
        font-weight: $fontWeight400;
        font-size: $fontSize14;
        line-height: $lineHeight17;
        color: $BtnPrimaryColorBlue;
        background: $backgroundTransparent;
        padding: $paddingZero;
        border: $d-none;
        margin: $marginZero  !important;
        text-transform: inherit;
        height: $heightAuto;
    }

    button.recoverPassword.btn.btn-primary:hover,
    button.recoverPassword.btn.btn-primary:focus,
    button.recoverPassword.btn.btn-primary:active,
    button.hidePassword.btn.btn-primary:hover,
    button.hidePassword.btn.btn-primary:focus,
    button.hidePassword.btn.btn-primary:active {
        background: $backgroundTransparent;
        box-shadow: $d-none  !important;
        color: $BtnPrimaryColorBlue;
    }

    .form-group {
        position: $positionRelative;
        margin-bottom: $margin27px;
    }
    .styleColorWhite{
        color: white;
    }
    .styleColorBlack{
        color: black;
    }

    .signupWrapper {
        background-image: url('../../assets/images/signupBg.jpg');
        background-size: $backgroundSizeCover;
        height: calc($height100per - 274px);
        padding-top: 136px;
    }
    .privacyPolicyWrapper {
        background-image: url('../../assets/images/signupBg.jpg');
        background-size: $backgroundSizeCover;
        height: calc($height100per - 274px);
        padding-top: 9vh;
    }
    .signupContent {
        padding-left: $paddingLeft113;
        padding-right: $paddingRight113;
        height: calc($height100vh - 274px);
        display: $d-flex;
        width: $width100Per;
        justify-content: $justify-content-SpaceBetween;
    }

    .signupContent .signupLeftContent {
        color: $Colorwhite;
    }

    button.backBtn.btn.btn-primary:hover,
    button.backBtn.btn.btn-primary:active,
    button.backBtn.btn.btn-primary:focus {
        box-shadow: $d-none  !important;
        background: $backgroundTransparent;
    }

    img#__lpform_formBasicEmail_icon,
    div#__lpform_formBasicPassword,
    img#__lpform_input_idx_0_icon {
        display: $d-none;
    }

    /* Success Popup CSS Start */
    button.crossBtn.btn.btn-primary,
    button.showPassword.btn.btn-primary:hover,
    button.crossBtn.btn.btn-primary:active,
    button.showPassword.btn.btn-primary:active,
    button.crossBtn.btn.btn-primary:focus,
    button.showPassword.btn.btn-primary:focus {
        box-shadow: $d-none  !important;
        background: $backgroundTransparent;
    }

    button.crossBtn.btn.btn-primary {
        background: $backgroundTransparent;
        border: $d-none;
        position: $positionAbsolute;
        right: 24px;
        top: 24px;
        padding: $paddingZero;
    }

    .successImg {
        position: $positionAbsolute;
        top: -75px;
        left: $Zeropx;
        right: $Zeropx;
        margin: $marginAuto;
    }

    /* Success Popup CSS End */
    .signupContent .row {
        width: $width100Per;
        margin-left: $Zeropx;
        margin-right: $Zeropx;
    }

    .text-danger {
        font-family: $fontFamilyRubik;
        font-weight: $fontWeight400;
        font-size: $fontSize12;
        line-height: $lineHeight16;
        display: $d-flex;
        align-items: $alignItemsCenter;
        color: $ColorDanger  !important;
        position: $positionAbsolute;
        bottom: -10px;
        width: $width100Per;

        img {
            margin-right: $margin9px;

        }
    }

    .containCheckbox {
        display: $d-inlineblock;
        position: $positionRelative;
        padding-left: $padding35px;
        margin-bottom: $margin30px;
        cursor: $cursorPointer;
        font-size: $fontSize14;
        line-height: $lineHeight17;
        height: $height25px;
        padding-top: $Threepx;
        -webkit-user-select: $d-none;
        -moz-user-select: $d-none;
        -ms-user-select: $d-none;
        user-select: $d-none;
    }

    /* Hide the browser's default checkbox */
    .containCheckbox input {
        position: $positionAbsolute;
        opacity: $opacityZero;
        cursor: $cursorPointer;
        height: $Zeropx;
        width: $Zeropx;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: $positionAbsolute;
        top: $Zeropx;
        left: $Zeropx;
        height: $width25;
        width: $height25px;
        border: 1px solid $ColorgreyGreyCheckbox;
        border-radius: $borderRadius3;
    }

    /* When the checkbox is checked, add a blue background */
    .containCheckbox input:checked~.checkmark {
        background-color: $BtnPrimaryColorBlue;
        border-color: $BtnPrimaryColorBlue  !important;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: $positionAbsolute;
        display: $d-none;
    }

    /* Show the checkmark when checked */
    .containCheckbox input:checked~.checkmark:after {
        display: $d-block;
    }

    /* Style the checkmark/indicator */
    .containCheckbox .checkmark:after {
        left: 7px;
        top: $Twopx;
        width: 9px;
        height: $height16px;
        border: solid $Colorwhite;
        border-width: $Zeropx $Twopx $Twopx $Zeropx;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .border-botm {
        border-bottom: 1px solid $ColorBorderGrey  !important;
    }

    .tooltip {
        font-size: $fontSize16;
        padding: $padding10px;
        min-width: 100px;

        &:after {
            content: '';
            position: $positionAbsolute;
            right: 47%;
            width: 12px;
            height: 12px;
            background-color: rgba(0, 0, 0, 1);
            transform: translateX(-50%);
            transform: rotate(45deg);
            bottom: 5px;
        }
    }


    /* Shimmer Css Start */
    .shimmer,
    .shimmerLight {
        width: $width100Per;
        height: $height100per
    }

    .shimmerLight {
        background: $shimmerBg;
        background-image: linear-gradient(to right, #e6e6e6 0%, #e5e5e6 20%, #e6e4e4 40%, #d9dcdd 100%);
        background-repeat: no-repeat;
        background-size: cover;
        display: $d-inlineblock;
        position: $positionRelative;

        animation-duration: 1.5s;
        -webkit-animation-duration: 1.5s;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        animation-name: placeholderShimmer;
        -webkit-animation-name: placeholderShimmer;
        animation-timing-function: linear;
        -webkit-animation-timing-function: linear;
    }

    .shimmer,
    .btn.btn-primary.downloadtBtn.shimmer {
        background: #cdd0d3;
        background-image: linear-gradient(to right, #c8cbcd 0%, #c7c8cb 20%, #bbbdbf 40%, #9b9d9f 100%);
        background-repeat: no-repeat;
        background-size: cover;
        display: $d-inlineblock;
        position: $positionRelative;

        animation-duration: 1.5s;
        -webkit-animation-duration: 1.5s;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        animation-name: placeholderShimmer;
        -webkit-animation-name: placeholderShimmer;
        animation-timing-function: linear;
        -webkit-animation-timing-function: linear;
    }



    @keyframes placeholderShimmer {
        0% {
            background-position: -1000px 0;
        }

        100% {
            background-position: 1000px 0;
        }
    }

    /* Shimmer Css End */
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: $ColorDark;
    display: $d-inlineblock;
    width: 24px !important;
    height: 24px;
    font-size: 11px;
    padding: 5px;
}

select.react-datepicker__month-select,
select.react-datepicker__year-select {
    width: 70px;
    height: 24px;
    border-radius: $borderRadius0;
    font-size: $fontSize12;
    color: $ColorDark;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
    font-size: $fontSize12;
    margin-bottom: 5px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: $BtnPrimaryColorBlue  !important;
}

/* Pagination CSS Start */
.paginationWrapper {
    display: $d-flex;
    justify-content: space-between;
    align-items: $alignItemsCenter;

    ul.pagination {
        align-items: $alignItemsCenter;
        justify-content: $justify-content-center;
        width: calc($width100Per - 100px);
        margin-bottom: $marginZero;

        li.disabled {
            pointer-events: $d-none;
            cursor: default;

            a {
                opacity: 0.3;
            }
        }

        li {
            a {
                width: 40px;
                height: 40px;
                display: $d-inlineblock;
                font-weight: $fontWeight400;
                font-size: $fontSize14;
                line-height: $lineHeight32;
                text-align: $textAlignCenter;
                letter-spacing: 0.21px;
                color: $ColorDark;
                padding: $padding5px;
                margin: $marginZero 3px;
                text-decoration: $d-none;
                cursor: $cursorPointer;

                &:hover {
                    background: $BtnPrimaryColorBlue;
                    border-radius: $borderRadius4;
                    color: $Colorwhite;
                }
            }

            a.leftArrow,
            a.rightArrow {
                width: $widthAuto;
                height: $heightAuto;
                padding: $paddingZero;
                margin: $marginZero $margin25px;

                &:hover {
                    background: $backgroundTransparent;
                    color: inherit;
                }
            }

            a.leftArrow {
                img {
                    margin-right: $margin10px;
                }
            }

            a.rightArrow {
                img {
                    transform: rotate(180deg);
                    margin-left: $margin10px;
                }
            }

            a.active {
                background: $BtnPrimaryColorBlue;
                border-radius: $borderRadius4;
                color: $Colorwhite;
            }
        }

    }

    .pageCount {
        h3 {
            font-weight: $fontWeight400;
            font-size: $fontSize14;
            line-height: $lineHeight17;
            text-transform: $textUppercase;
            color: $ColorgreyGreyCheckbox;
        }
    }
}

/* Pagination CSS End */

/* Multiple Dropdown CSS Start */

span.css-1okebmr-indicatorSeparator,
.css-tlfecz-indicatorContainer svg.css-tj5bde-Svg,
.css-1hb7zxy-IndicatorsContainer svg,
.css-tlfecz-indicatorContainer svg,
.css-1gtu0rjindicatorContainer svg {
    display: $d-none;
}

.css-1s2u09g-control,
.css-1pahdxg-control {
    min-height: $Height46px  !important;
    border: 1px solid $ColorBorderGrey  !important;
    font-size: $fontSize14;
}

.css-1pahdxg-control {
    .css-1hb7zxy-IndicatorsContainer {
        transform: rotate(180deg);
    }
}

.css-1pahdxg-contro {
    .css-tlfecz-indicatorContainer {
        transform: rotate(180deg);
    }
}

.css-tlfecz-indicatorContainer+span.css-1okebmr-indicatorSeparator+.css-tlfecz-indicatorContainer {
    display: $d-none;
}

.css-1hb7zxy-IndicatorsContainer {
    height: 40px;
    width: 40px;
    background-image: url(../images/angleDownIcon.svg);
    background-size: 15px 8px;
    background-repeat: no-repeat;
    transition: 0.5s all ease-in-out;
    background-position: 15px 18px;
}

.css-tlfecz-indicatorContainer {
    height: $height40;
    width: 40px;
    background-image: url(../images/angleDownIcon.svg);
    background-size: 15px 8px;
    background-repeat: no-repeat;
    transition: 0.5s all ease-in-out;
    background-position: 15px 18px;
}

.css-319lph-ValueContainer {
    height: 44px;
    font-size: $fontSize14;
}

.css-4ljt47-MenuList {
    font-size: $fontSize14;
    color: $ColorLightGrey;
}

.css-14el2xx-placeholder {
    color: $ColorLightGrey  !important;
}

.css-12jo7m5 {
    font-size: $fontSize14  !important;
}

/* Multiple Dropdown CSS End */

/* Edit Delete Duplicate dropdwon Option CSS Start */
.dotsOptions {
    button {
        width: 30px;
        height: 30px;
        background-image: url(../images/am-share-img.png);
        background-repeat: no-repeat;
        background-position: 5px -56px;
        background-color: $Colorwhite;
        border-radius: $borderRadius100;
        border: $d-none;
        transition: 0.5s all ease-in-out;
        margin-left: $margin10px;

        &:after {
            display: $d-none;
        }

        &:hover {
            background-position: -27px -56px;
            background-color: $BtnPrimaryColorBlue;
        }
    }

    .dropdown-menu {
        width: 190px;
        padding: $paddingZero;

        a.dropdown-item:last-child {
            border-bottom: $d-none;
        }

        a.dropdown-item.disable {
            position: relative;
            // pointer-events: none;
            &::before {
                content: '';
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: rgba(255,255,255,0.55);
                z-index: 9;
                position: absolute;
                cursor: none;
                pointer-events: none;
                height: 100%;
                width: 100%;
            }
        }

        a.dropdown-item {
            font-weight: $fontWeight400;
            font-size: $fontSize12;
            line-height: $lineHeight14;
            color: $ColorDark;
            padding: 7px $padding20px;
            display: $d-flex;
            align-items: $alignItemsCenter;
            border-bottom: 1px solid $BorderColorDropdown;
            border-top: $d-none;
            border-right: $d-none;
            border-left: $d-none;

            span {
                background-image: url(../images/am-share-img.png);
                background-repeat: no-repeat;
                width: $width20;
                height: $height20px;
                display: $d-inlineblock;
                margin-right: $margin10px;
                transition: 0.5s all ease-in-out;
            }

            .editIcon {
                background-position: 0 -89px;
            }

            .DeleteIcon {
                background-position: 0 -124px;
            }

            .DuplicateIcon {
                background-position: 0 -155px;
            }

            &:hover {
                background: $BtnPrimaryColorBlue;
                color: $Colorwhite
            }

            &:hover .editIcon {
                background-position: -30px -89px;
            }

            &:hover .DeleteIcon {
                background-position: -30px -124px;
            }

            &:hover .DuplicateIcon {
                background-position: -30px -155px;
            }
        }
    }
}

/* Edit Delete Duplicate dropdwon Option CSS End */

/* Secondary Button CSS Start */
button.secondaryBtn {
    background: $secondaryColor;
    border: 1px solid $secondaryColor;
    border-radius: $borderRadius4;
    padding: $padding10px;
    font-size: $fontSize14;
    color: $Colorwhite;
}

/* Secondary Button CSS End */

.TopWrap {
    .SortNewUser {
        .sortWrap {
            .DropdownWrap {
                button {
                    padding-right: $padding40px;
                }
            }
        }
    }

    .SearchWrap {
        .DropdownWrap.allTeamsWrap.dropdown button {
            padding-right: 25px;
            width: 270px;
            text-transform: $capitalize;

            &::after {
                right: 8px;
            }
        }

        .DropdownWrap.allTeamsWrap.dropdown {
            .dropdown-menu {
                height: 180px;
                width: 270px;

                .text-center.noRecord {
                    margin: 40px 0;

                    h4.noRecord {
                        font-weight: 400;
                    }
                }
            }
        }

        .DropdownWrap {
            button {
                min-width: 75px;
                padding-right: 35px;
            }
        }
    }
}

h4.noRecord {
    font-size: $fontSize28;
    margin: $margin20px $marginZero;
}

/* Reminder CSS Start */

.reminder {
    background: $ColorBackgroundGreyLightAFB;
    border: 1px solid $ColorBorderGrey;
    border-radius: $borderRadius4;
    padding: $padding12px;
    align-items: $textAlignCenter;
    height: $height100per;
    position: $positionRelative;

    .remindericon {
        margin-right: $margin10px;
    }

    .reminderText {
        h3 {
            font-weight: $fontWeight400;
            font-size: $fontSize16;
            line-height: $lineHeight20;
            color: $ColorDark;
            font-family: $fontFamilyRubik;
        }

        p {
            margin-bottom: $marginZero;
            font-weight: $fontWeight400;
            font-size: $fontSize12;
            line-height: $lineHeight14;
            color: $ColorgreyGreyCheckbox;
            font-family: $fontFamilyRubik;
        }
    }

    button.delReminder.btn.btn-primary {
        background: $backgroundTransparent;
        position: $positionAbsolute;
        right: $Tenpx;
        bottom: $Tenpx;
        padding: $paddingZero;
        border: $d-none;

        &:focus {
            box-shadow: $d-none;
        }
    }
}

/* Reminder CSS End */
button.btn-close.closebuttonbtn {
    position: $positionAbsolute;
    background: $backgroundTransparent;
    border: $d-none;
    right: $Tenpx;
    top: $Tenpx;
    padding: $paddingZero;
}

.timeWrap {
    background-image: url('../images/timerIcon.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) 11px;

    input[type="time"] {
        -webkit-appearance: $d-none;
        appearance: $d-none;
        -moz-appearance: $d-none;
        background: $backgroundTransparent;
    }
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: $d-none;
}

.AddteamPopup.NewReminderPopup {
    textarea.form-control {
        border-radius: $borderRadius4;
        height: $height100px;
        resize: inherit;
        padding: $padding10px;
        font-weight: $fontWeight400;
        font-size: $fontSize14;
        line-height: $lineHeight17;
        color: $ColorLightGrey;
    }

    .modal-footer {
        button.btn.btn-primary[type="submit"] {
            width: $widthAuto;
            margin: $marginZero;
            padding: $padding10px $padding24px;
        }
    }
}

/* image crop CSS Start */
.AddteamPopup {
    .modal-content {
        .modal-body {
            span.uploadField.imgPreview {
                //  height: $heightAuto;
                height: 360px;
                border-radius: $borderRadius8;
                overflow: hidden;
            }
        }
    }
}

crop-container {
    position: $positionAbsolute;
    top: $Zeropx;
    left: $Zeropx;
    right: $Zeropx;
    bottom: 80px;
}

.controls {
    position: $positionAbsolute;
    bottom: $Zeropx;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);
    height: $height40;
    display: $d-flex;
    align-items: $alignItemsCenter;
}

.slider {
    padding: $padding22px $paddingZero;
}

.zoom-range {
    -webkit-appearance: $d-none;
    -moz-appearance: $d-none;
    height: 2px;
    border-top: 2px solid #CED0D4;
    border-bottom: 2px solid #CED0D4;
    // background: #3f51b5;
    background: #3f51b5;
    width: 100%;
}

.reactEasyCrop_CropArea {
    color: rgba(255, 255, 255, 0.5) !important;
    height: 300px !important;
    width: 300px !important;
}

.zoom-range::-moz-range-thumb {
    -webkit-appearance: $d-none;
    -moz-appearance: $d-none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
    -webkit-appearance: $d-none;
    -moz-appearance: $d-none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

button.cropButton,
.editImg {
    display: $d-none;
}

span.uploadField.imgPreview {
    .sentImg {
        width: $width100Per;
        height: $height100per;

        img {
            max-height: $height100per;
            max-width: $width100Per;
        }
    }

    .fileUploadWrap {
        position: $positionAbsolute;
        top: $Zeropx;
        right: $Zeropx;

        .editImg {
            position: $positionAbsolute;
            width: $width30  !important;
            height: $height30px  !important;
            right: $Fivepx  !important;
            left: auto !important;
            opacity: $opacityOne  !important;
            top: $Tenpx;
            display: $d-inlineblock;
            background: $Colorwhite;
            cursor: $cursorPointer;
            border-radius: $borderRadius100;

            img {
                border-radius: $borderRadius0  !important;
                padding: 7px $paddingZero;
            }
        }

        input[type="file"]#formFile {
            height: $height100per;
            padding: $paddingZero;
            min-height: $height100per  !important;
            cursor: $cursorPointer;
            width: $width100Per  !important;
        }

        button.cropButton {
            position: $positionAbsolute;
            top: 50px;
            right: $Fivepx;
            border: $d-none;
            z-index: 11;
            display: $d-inlineblock;
            background: $Colorwhite;
            width: $width30  !important;
            height: $height30px  !important;
            border-radius: $borderRadius100;

            img {
                width: 18px;
                padding: 5px $paddingZero;
            }
        }

    }

    /* Blue Background */
    .blue {
        text-shadow: 0px 1px 0px #4D7CFE;
        background-image: -webkit-linear-gradient(top, #5782f8, #4d7cfd);
        background-image: -moz-linear-gradient(top, #5782f8, #4d7cfd);
        background-image: -o-linear-gradient(top, #5782f8, #4d7cfd);
        background-image: linear-gradient(to bottom, #5782f8, #4d7cfd);
        -webkit-animation: blue 2s infinite;
        -moz-animation: blue 2s infinite;
        -o-animation: blue 2s infinite;
        animation: blue 2s infinite;
    }
}

.MuiSlider-rail {
    height: 4px !important;
    background-color: #CED0D4 !important;
}

.MuiSlider-track {
    height: 4px !important;
    background-color: $BtnPrimaryColorBlue  !important;
}

.MuiSlider-thumb {
    width: 20px !important;
    height: 20px !important;
    margin-top: -10px !important;
    color: $Colorwhite;

}

/* Blue Shadow */
@-moz-keyframes blue {

    0%,
    100% {
        -moz-box-shadow: 1px 0px 19px 4px rgba(0, 130, 196, 0.7), inset 0px 0px 10px rgba(255, 255, 255, 0.5);
        box-shadow: 1px 0px 19px 4px rgba(0, 130, 196, 0.7), inset 0px 0px 10px rgba(255, 255, 255, 0.5);
    }

    50% {
        -moz-box-shadow: 0px 0px 0px 0px rgba(0, 130, 196, 0), inset 0px 0px 0px rgba(255, 255, 255, 0);
        box-shadow: 0px 0px 0px 0px rgba(0, 130, 196, 0), inset 0px 0px 0px rgba(255, 255, 255, 0);
    }
}

@-webkit-keyframes blue {

    0%,
    100% {
        -webkit-box-shadow: 1px 0px 19px 4px rgba(0, 130, 196, 0.7), inset 0px 0px 10px rgba(255, 255, 255, 0.5);
        box-shadow: 1px 0px 19px 4px rgba(0, 130, 196, 0.7), inset 0px 0px 10px rgba(255, 255, 255, 0.5);
    }

    50% {
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 130, 196, 0), inset 0px 0px 0px rgba(255, 255, 255, 0);
        box-shadow: 0px 0px 0px 0px rgba(0, 130, 196, 0), inset 0px 0px 0px rgba(255, 255, 255, 0);
    }
}

@-o-keyframes blue {

    0%,
    100% {
        box-shadow: 1px 0px 19px 4px rgba(0, 130, 196, 0.7), inset 0px 0px 10px rgba(255, 255, 255, 0.5);
    }

    50% {
        box-shadow: 0px 0px 0px 0px rgba(0, 130, 196, 0), inset 0px 0px 0px rgba(255, 255, 255, 0);
    }
}

@keyframes blue {

    0%,
    100% {
        box-shadow: 1px 0px 19px 4px rgba(0, 130, 196, 0.7), inset 0px 0px 10px rgba(255, 255, 255, 0.5);
    }

    50% {
        box-shadow: 0px 0px 0px 0px rgba(0, 130, 196, 0), inset 0px 0px 0px rgba(255, 255, 255, 0);
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
        overflow: hidden;
        width: $width100Per;
        -webkit-appearance: $d-none;
        background-color: #CED0D4;
        height: 4px;
    }

    input[type='range']::-webkit-slider-runnable-track {
        height: 10px;
        -webkit-appearance: $d-none;
        color: #3f51b5;
        margin-top: -1px;
    }

    input[type='range']::-webkit-slider-thumb {
        width: 12px;
        -webkit-appearance: $d-none;
        height: 12px;
        cursor: ew-resize;
        background: $Colorwhite;
        box-shadow: -80px 0 0 80px $BtnPrimaryColorBlue;
        transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 50%;
        overflow: visible;
        z-index: 999999;
    }

}

.mb-6 {
    margin-bottom: $margin30px;
}

/** FF*/
input[type="range"]::-moz-range-progress {
    background-color: #43e5f7;
}

input[type="range"]::-moz-range-track {
    background-color: #3f51b5;
}

/* IE*/
input[type="range"]::-ms-fill-lower {
    background-color: #43e5f7;
}

input[type="range"]::-ms-fill-upper {
    background-color: #3f51b5;
}

/* image crop CSS End */

.ContentWrap {
    .CreateTeamWrap.CreateTeamFirst {
        .TopWrap {
            .SearchWrap {
                position: $positionRelative;

                button#dropdown-basic {
                    width: 200px;
                    background: $ColorBackgroundGrey;
                    border-radius: $borderRadius50;
                    padding: $padding10px $padding20px;
                    height: $height40;
                    border: $d-none;
                    color: $ColorLightGrey;
                    position: $positionRelative;

                    &::after {
                        top: 17px;
                    }
                }
            }
        }
    }
}

/* Team Module Topbar Css Start */
.teamTopBarWrap {
    position: $positionRelative;
    background-color: $ColorBackgroundGrey;
    margin: $marginZero;
    height: $heightAuto;
    padding: $paddingZero $padding30px $paddingZero $padding30px;

    .TopWrap {
        height: $Height64px;
        border-bottom: 1px solid $ColorBorderGrey;
        padding: $padding12px $padding30px;
        background-color: $Colorwhite;
        display: $d-flex;
        justify-content: $justify-content-end  !important;

        .SearchWrap {
            position: $positionRelative;

            select {
                background: $ColorBackgroundGrey;
                border-radius: $borderRadius100;
                height: $height40;
                border: $border0;
                width: 200px;
                padding: $padding12px $padding19px;
                font-family: $fontFamilyRubik;
                font-weight: $fontWeight400;
                font-size: $fontSize12;
                line-height: $lineHeight14;
                text-transform: $textUppercase;
                color: $ColorgreyGreyCheckbox;
                -moz-appearance: $d-none;
                -webkit-appearance: $d-none;
                appearance: $d-none;
            }

            .downarrow {
                position: $positionAbsolute;
                right: 24px;
                top: 17px;
                width: 12px;
            }

            .DropdownWrap.dropdown {
                button#timeFormat {
                    width: $widthAuto;
                    padding-right: $padding30px;

                    &::after {
                        right: $Tenpx;
                    }
                }
            }
        }

        .SortNewUser {
            .SortIcon {
                font-weight: $fontWeight400;
                font-size: $fontSize12;
                line-height: $lineHeight14;
                text-transform: $textUppercase;
                color: $ColorgreyGreyCheckbox;
                margin-right: $margin15px;

                img {
                    margin-right: 8px;
                }
            }

            select.form-select {
                font-weight: $fontWeight400;
                font-size: $fontSize12;
                line-height: $lineHeight14;
                text-transform: $textUppercase;
                color: $ColorgreyGreyCheckbox;
                border: $border0;
            }

            .NewUserWrap {
                border-left: 1px solid $ColorBorderGrey;
                margin-left: $margin14px;
                padding-left: $padding15px;

                p {
                    font-weight: $fontWeight400;
                    font-size: $fontSize12;
                    line-height: 14px;
                    text-transform: $textUppercase;
                    color: $ColorgreyGreyCheckbox;
                    margin: $marginZero;
                    cursor: pointer;

                    img {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}


/* Team Module Topbar Css End */

.react-datepicker__day.react-datepicker__day--disabled {
    opacity: 0.6;
}

// Product Email Modal  Css Start
.EmailModal {
    .modal-dialog {
        margin-top: 120px;

        .modal-content {
            padding: 20px;
            border-radius: 8px;

            .modal-header {
                justify-content: center;
                padding: 100px 20px 10px;
                border: $d-none;

                .modal-title.h4 {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    color: #252631;
                }

                span.ModalLogo {
                    background: #fff;
                    box-shadow: 6px 6px 14px rgb(0 0 0 / 8%);
                    border-radius: 50%;
                    height: 130px;
                    width: 130px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: -65px;
                    left: 50%;
                    transform: translate(-50%, 0px);
                }

                .closebutton {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    cursor: pointer;
                }
            }

            .modal-body {
                padding: $padding10px $padding10px $padding20px $padding10px;

                .emailField {
                    label {
                        font-weight: $fontWeight400;
                        font-size: $fontSize14;
                        line-height: $lineHeight17;
                        color: #252631;
                    }
                }
            }

            .modal-footer {
                border: $d-none;
                padding-right: 0;

                button.btn.btn-primary.sendEmail.btn.btn-primary {
                    background: #4D7CFE;
                    border-radius: 4px;
                    border-color: #4D7CFE;
                    padding: 14px 35px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }
            }
        }
    }
}


// Product Email Modal  Css End

.allTeamsWrap {
    button {
        border: 1px solid $ColorDark  !important;
        color: $ColorDark  !important;
    }
}

/* middle Css */
.transformMiddle {
    position: $positionAbsolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    h3 {
        white-space: nowrap;
    }

    button.btn.btn-primary {
        padding: $padding10px $padding20px;
        font-size: $fontSize16;
        background-color: $BtnPrimaryColorBlue;
        border: 1px solid $BtnPrimaryColorBlue;
        border-radius: $borderRadius4;
        margin-top: $margin10px;
    }
}

/* middle Css */

/* Popup CSS Start */
.AddteamPopup.ColorAddPopup,
.AddteamPopup.fabricsModal,
.AddteamPopup.seasonsModal,
.AddteamPopup.categoryModal,
.AddteamPopup.tagsModal {
    .modal-dialog {
        margin-top: $margin120px;
        max-width: 575px !important;
    }

    .modal-content {
        background: $Colorwhite;
        box-shadow: 6px 6px 14px rgb(0 0 0 / 8%);
        border-radius: $borderRadius8;
        height: $heightAuto;

        .modal-header {
            display: $d-block;
            align-items: $alignItemsCenter;
            justify-content: $justify-content-center;
            padding: 100px $padding20px $padding10px;
            border-bottom: 0px solid #dee2e6;
            text-align: $textAlignCenter;

            .ModalLogo {
                background: $Colorwhite;
                box-shadow: 6px 6px 14px rgb(0 0 0 / 8%);
                border-radius: 50%;
                height: 130px;
                width: 130px;
                display: $d-flex;
                align-items: $alignItemsCenter;
                justify-content: $justify-content-center;
                position: $positionAbsolute;
                top: -65px;
                left: 50%;
                transform: translate(-50%, 0px);
            }

            .modal-title.h4 {
                font-weight: $fontWeight500;
                font-size: $fontSize20;
                line-height: $lineHeight16;
                text-align: $textAlignCenter;
                color: $ColorDark;
                font-family: $fontFamilyRubik;
            }

            .closebutton {
                position: $positionAbsolute;
                right: 12px;
                top: 12px;
                cursor: $cursorPointer;
            }
        }

        .modal-body {
            padding: $paddingZero $padding30px $paddingZero;

            p {
                font-family: $fontFamilyRubik;
                font-weight: $fontWeight400;
                font-size: $fontSize18;
                line-height: $lineHeight26;
                text-align: $textAlignCenter;
                color: $ColorgreyGreyCheckbox;
                max-width: 350px;
                margin: $marginZero $marginAuto;
            }

            .importWrapOuter {
                min-height: $Height46px;
                border-radius: $borderRadius4;
                border: 1px solid $ColorBorderGrey;
                color: $ColorLightGrey;
                font-family: $fontFamilyRubik;
                font-weight: $fontWeight400;
                font-size: $fontSize14;
                line-height: $lineHeight17;
                -webkit-appearance: $d-none;
                background-image: $d-none  !important;
                padding: $paddingZero;
                display: $d-flex;
                align-items: $alignItemsCenter;

                .importWrapInner {
                    min-height: $Height46px;
                    display: $d-flex;
                    align-items: $alignItemsCenter;
                    background-color: $ColorBackgroundGreyLight;
                    border: 1px solid $ColorBorderGrey;
                    border-top: $d-none;
                    border-left: $d-none;
                    border-bottom: $d-none;
                    padding: $paddingZero $padding30px;
                    position: $positionRelative;
                    cursor: $cursorPointer;

                    label {
                        margin-bottom: $marginZero;
                        cursor: $cursorPointer;
                    }

                    input#formFile {
                        position: $positionAbsolute;
                        opacity: $opacityZero;
                        min-height: $Height46px;
                        left: $Zeropx;
                        cursor: $cursorPointer;
                    }
                }
            }

            span.form-text.text-muted {
                font-size: 11px;
            }
        }

        .modal-footer {
            display: $d-flex;
            flex-wrap: wrap;
            align-items: $textAlignCenter;
            justify-content: $justify-content-end;
            padding: $paddingZero $padding24px $padding35px;
            border-top: 0px solid $BorderColor;
            border-bottom-right-radius: calc(0.3rem - 1px);
            border-bottom-left-radius: calc(0.3rem - 1px);
        }

        button.btn.btn-secondary {
            background: $ColorBackgroundGreyLight;
            border-radius: $borderRadius4;
            font-weight: $fontWeight500;
            font-size: $fontSize14;
            line-height: $lineHeight17;
            text-align: $textAlignCenter;
            text-transform: $textUppercase;
            color: $ColorgreyGreyCheckbox;
            height: $Height46px;
            width: 150px;
            border: $border0;
            margin-right: $margin30px;
        }

        button.btn.btn-primary {
            background: $BtnPrimaryColorBlue;
            border-radius: $borderRadius4;
            font-weight: $fontWeight500;
            font-size: $fontSize14;
            line-height: $lineHeight17;
            text-align: $textAlignCenter;
            text-transform: $textUppercase;
            color: $Colorwhite;
            height: $Height46px;
            width: 150px;
            border: $border0;
            letter-spacing: 0.8px;
            margin-bottom: $marginZero;

            // &:hover {
            //     background: $PrimaryColorBlueHover;
            // }
        }

    }
}

/* Popup CSS End */


body {
    .privacyPolicyWrapper {
        height: auto;
        .signupWrapper {
            background: #fff;
            height: auto;
            padding-top: 0;
        }
    }
}

/* media Query start */

@media (min-width: 769px) and (max-width: 1140px) {
    .teamTopBarWrap {
        padding: $paddingZero $padding15px $paddingZero $padding15px;
    }
}

@media screen and (max-width: 85.375em),
screen and (max-height: 48px) {
    body {

        .signupWrapper,
        .signupWrapper.OtpPage {
            height: $heightAuto;

            .signupContent {
                height: calc($height100vh - 274px);
                display: $d-flex;
                width: $width100Per;
                justify-content: $justify-content-SpaceBetween;
            }
        }
    }
}

@media only screen and (max-width: 810px) and (min-width: 790px) {
    body {
        .LoginWrapper {
            .signupContent {
                height: calc($height100vh - 285px) !important;
            }
        }
    }
}

@media only screen and (max-width: 896px) and (min-width: 769px) {
    body {
        .LoginWrapper {
            .signupContent {
                height: $heightAuto;
            }
        }
    }
}

/* 1199px media Query */
@media only screen and (max-width: 74.938em) {
    .reminderBlock {
        margin: 5px $marginZero;
    }
}

// 768px Media Query
@media only screen and (max-width: 48em) {
    .teamTopBarWrap {
        padding: $paddingZero $padding10px;

        .TopWrap {
            padding: $padding12px $padding10px;

            .SortNewUser {
                .SortIcon {
                    margin-right: 8px;
                    display: $d-flex;

                    img {
                        margin-right: 4px;
                    }
                }

                .NewUserWrap {
                    margin-left: 5px;
                }

                button.NewUserWrap.secondaryBtn {
                    padding: $padding10px 8px;
                }

                .sortWrap {
                    .DropdownWrap {
                        button {
                            padding-right: $padding24px;
                            font-size: $fontSize12;

                            &::after {
                                right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 767px media Query */
@media (max-width: 47.938em) {
    body {
        .signupWrapper {
            .signupContent {
                height: $heightAuto;
            }
        }

        label {
            margin-bottom: $margin10px;
        }

        .productOptions {
            .shareOptions {
                .shareBlock {
                    width: 300px;
                    left: -170px;
                }
            }
        }
    }
}

/* 768px media Query */
@media (max-width: 48em) {
    .ContentWrap {
        width: $width100Per  !important;
        display: $d-block  !important;
        position: $positionRelative  !important;
        margin-left: $marginZero  !important;
        height: $heightAuto  !important;
    }

    .CreateTeamWrap.conditionWiseProduct {
        .TopWrap {
            .SearchWrap {
                margin-bottom: $marginZero;
            }
        }
    }
}


@media only screen and (max-width: 600px) {
    body {
        .productOptions {
            .shareOptions {
                .shareBlock {
                    width: 280px;
                    left: -130px;
                }
            }
        }
    }

    .ContentWrap {
        .CreateTeamWrap {
            .WallProducts {
                .WallMainProducts {
                    ul {
                        li {
                            .productInner {
                                .shareBlock {
                                    .BtnRight {
                                        margin-top: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* 575px media Query */
@media (max-width: 35.938em) {
    .paginationWrapper {
        flex-direction: column;

        ul.pagination {
            margin-bottom: $margin20px;

            li {

                a.leftArrow,
                a.rightArrow {
                    margin: $marginZero 5px;
                }
            }
        }
    }

    .deletePopup {
        .modal-content {
            .modal-footer {
                justify-content: $justify-content-center  !important;
                padding: $paddingZero $padding15px $padding35px  !important;
                flex-wrap: nowrap !important;

                button {
                    width: 130px !important;
                }
            }
        }
    }
}

/* 480px media Query */
@media only screen and (max-width: 30em) {
    .reactEasyCrop_CropArea {
        height: 230px !important;
        width: 230px !important;
    }

    body {
        .productOptions {
            .shareOptions {
                .shareBlock {
                    left: -170px;
                }
            }
        }
    }

    .paginationWrapper {
        ul.pagination {
            width: 100%;

            li {
                a {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

/* 325px media Query */

@media only screen and (max-width: 20.3125em) {
    body {
        .productOptions {
            .shareOptions {
                .shareBlock {
                    width: 275px;
                    left: -108px;
                }
            }
        }
    }
}