@import "./../../../assets/css/functions.scss";
@import "./../../../assets/css/variables.scss";

.signupWrapper {
    .header {
        display: $d-flex;
        position: $positionRelative;
        background: #fff;
        box-shadow: 0px 6px 6px 0px rgb(0 82 224 / 10%);
        padding: 12px 0px 12px 40px;
        height: 70px;
        position: fixed;
        align-items: center;
        left: 0;
        right: 0;
        top: 0;
        z-index: 9999;

        .navbar-brand {
            h2 {
                color: #4D7CFE;
                font-family: "Rubik", Sans-serif;
                font-size: 28px;
                font-weight: 400;
                margin: 0;
            }
        }

        label.form-label {
            font-family: 'Rubik', sans-serif;
            font-style: normal;
            font-weight: $fontWeight400;
            font-size: $fontSize14;
            line-height: $lineHeight17;
            color: $ColorDark;
        }

        nav.navbar {
            background: transparent !important;
            // width: 70%;
            position: relative;
            z-index: 1;
            // display: block;
        }

        .signupContent {
            padding: 0 11.3rem;

            .signupLeftContent {
                color: $Colorwhite;
            }
        }

        a.nav-link {
            font-style: normal;
            font-weight: $fontWeight400;
            font-size: $fontSize15;
            line-height: $lineHeight20;
            display: $d-flex;
            align-items: center;
            text-align: center;
            color: #778CA2;
            font-family: 'Rubik', sans-serif;
            margin-left: $maginRight12;
            margin-right: $maginLeft12;
            justify-content: $justify-content-center;

            &:hover, &:focus,
            &:active {
                color: #4D7CFE;
            }
        }

        // a.nav-link:last-child {
        //     background: $Colorwhite;
        //     color: $ColorDarkGrey  !important;
        //     border-radius: $borderRadius4;
        //     width: $width87;
        //     height: $height38;
        //     margin-right: $maginRight0;
        //     padding-right: $paddingRight55rem;
        //     padding-left: $paddingLeft55rem;
        //     text-align: $textAlignCenter;
        //     display: $d-flex;
        //     justify-content: $justify-content-center;
        // }

        // a.nav-link:nth-last-child(2) {
        //     width: $width80;
        //     height: $height38;
        //     border: 2px solid $Colorwhite;
        //     border-radius: $borderRadius4;
        // }

        .mobileMenu {
            display: $d-none;
        }

        .btnsGroup {
            display: flex;
            align-items: center;
            margin-right: 220px;
            position: relative;
            z-index: 1;

            a {
                font-size: 15px;
                font-weight: 400;
                text-transform: uppercase;
                fill: #FFFFFF;
                color: #FFFFFF;
                background-color: #4D7CFE;
                border-radius: 8px;
                padding: 6px 25px 6px 25px;
                margin: 0 8px;
                text-decoration: none;


                &:hover {
                    color: #FFFFFF;
                    background-color: #99A9BB;
                }
            }
        }

        &::after {
            background-color: #F2F4F6;
            content: '';
            position: absolute;
            width: 83%;
            height: 50px;
            right: 0;
            border-radius: 50px 0 0 50px;
            bottom: 12px;
        }
    }
}

.sidenav {
    height: 0;
    width: 100%;
    position: absolute;
    z-index: 999999;
    top: 0;
    left: 0;
    background-color: rgba(77, 124, 254, 0.85);
    overflow-x: hidden;
    padding-top: 0px;
    -moz-transition: height 0.5s ease;
    -webkit-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: height 0.5s ease;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: $fontSize16;
    color: $Colorwhite;
    display: $d-block;
    transition: 0.5s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.closebtn {
    position: absolute;
    margin-left: 0;
    padding-left: 8px;
    background: transparent;
    border: none;
    top: 22px;
    right: 15px;

    img {
        width: 20px;
    }
}

// @media screen and (max-height: 450px) {
//     .sidenav {
//         padding-top: 15px;
//     }

//     .sidenav a {
//         font-size: 18px;
//     }
// }


@media (min-width: 768px) {
    .navbar-expand-md .navbar-collapse {
        justify-content: flex-end;
        flex-grow: inherit;
    }
}

@media only screen and (max-width: 1199px) {
    .signupWrapper {
        .header {
            .btnsGroup {
                margin-right: 100px;
            }

            a.nav-link {
                margin-left: 6px;
                margin-right: 6px;
            }

            &::after {
                width: 78%;
            }
        }
    }

}

@media only screen and (max-width: 991px) {
    body {
        .signupWrapper {
            .header {
                nav.navbar.navbar-expand-md.navbar-dark.bg-dark {
                    display: $d-none;
                }

                .mobileMenu {
                    display: $d-flex;
                    // position: absolute;
                    z-index: 9;
                    align-items: center;
                    margin-right: 20px;

                    .menuToggle {
                        color: #4D7CFE;
                        line-height: normal;
                        border: 1px solid #4D7CFE;
                        padding: 2px 6px;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    body {

        .signupWrapper {
            button.navbar-toggler {
                display: $d-none;
            }

            .header {
                padding: $padding20px;

                &::after {
                    width: 60%;
                }

                nav.navbar.navbar-expand-md.navbar-dark.bg-dark {
                    display: $d-none;
                }

                .mobileMenu {

                    .menuToggle {
                        color: #4D7CFE;
                        line-height: normal;
                        border: 1px solid #4D7CFE;
                        padding: 2px 6px;
                        border-radius: 4px;
                    }

                    .sadenavInner {
                        padding: 50px 20px 20px;
                    }
                }

                .mobileMenu {
                    margin-right: 0;
                }

                .btnsGroup {
                    margin-right: 0;

                    a {
                        font-size: 14px;
                        padding: 6px 15px 6px 15px;
                        margin: 0 5px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    body {
        .signupWrapper {
                padding-top: 88px;
            .header {
                padding: 20px 10px;
                align-items: center;
                &::after {
                    width: 65%;
                }
                .navbar-brand {
                    h2 {
                        font-size: 22px;
                    }
                }

                nav.navbar {
                    z-index: 1;
                    display: none;
                }

                .btnsGroup {
                    a {
                        font-size: 14px;
                        padding: 6px 10px 6px 10px;
                        margin: 0 3px;
                    }
                }
                .mobileMenu {
                    .menuToggle {
                        padding: 2px 6px;
                        font-size: 24px !important;
                    }
                }
            }
        }
    }
}